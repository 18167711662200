import type {FC} from 'react'
import {
	useEffect, useRef, useCallback,
} from 'react'
import type {ScaleTime} from 'd3'
import {
	select,
	axisBottom,
} from 'd3'
import {getFormattedDate} from '../../../util-functions'
import type {UserInterface} from '../../interfaces/user.ts'
import type {UserContextProps} from '../../../containers/App/UserProvider'
import {useUserContext} from '../../../containers/App/UserProvider'

interface AxisProps {
	width: number,
	xScale: ScaleTime<number, number>,
	height: number,
	displayInUTC?: boolean,
	summarizeBy?: string,
	ticks?: number,
}

const TimeXAxis: FC<AxisProps> = ({
	width,
	xScale,
	height,
	displayInUTC = false,
	summarizeBy,
	ticks,
}) => {
	// const {currentUser} = useUserContext() as UserContextProps
	const timeXAxisRef = useRef<SVGGElement | null>(null)
	const {currentUser} = useUserContext() as UserContextProps

	const getNumberOfTicks = useCallback((width: number): number => {
		switch (true) {
			case width < 300:
				return 1
			case width >= 300 && width < 600:
				return 2
			case width >= 600 && width < 900:
				return 3
			case width >= 900:
				return 4
			default:
				return 4
		}
	}, [])

	useEffect(() => {
		const {current} = timeXAxisRef
		if (!current) return
		const xAxisSelection = select(current as SVGGElement)
		xAxisSelection.call(
			axisBottom(xScale)
				.ticks(ticks || getNumberOfTicks(width))
				.tickSizeInner(-height)
				.tickPadding(10)
				.tickSizeOuter(5)
				.tickFormat((value) => {
					if (summarizeBy === 'week') {
						const parts = value.toString().split(' ')
						const weekNumber = parts[1].replace(',', '')
						const year = parts[2]
						return `${year}-${weekNumber}`
					}

					if (!displayInUTC) {
						return getFormattedDate(value.toString(), currentUser as UserInterface, {
							showYear: true,
							showTime: true,
							showSeconds: true,
						})
					}
					const date = new Date(value as number)
					const locale = currentUser?.userprofile?.preferred_locale || 'en-US'

					const formattedDate = date.toLocaleDateString(locale, {
						month: '2-digit',
						day: '2-digit',
						timeZone: 'UTC',
					})

					if (locale === 'de') {
						return formattedDate.replace(/\.$/, '')
					}

					return formattedDate
				}),
		)
		// Apply MUI styles to ticksInner
		xAxisSelection.selectAll('.tick line')
			.attr('stroke', 'currentColor')
			.attr('stroke-width', 0.5)
			.attr('stroke-opacity', 0.2)
	}, [xScale, height, currentUser, displayInUTC, getNumberOfTicks, summarizeBy, ticks, width])

	return (
		<g
			data-testid="mbai-main-d3-time-x-axis"
			ref={timeXAxisRef}
		/>
	)
}

export default TimeXAxis
