import type {BaseSyntheticEvent, FC} from 'react'
import {useState, useCallback} from 'react'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import {
	Grid, IconButton, Tooltip, Typography, CircularProgress,
} from '@mui/material/'
import {Box} from '@mui/system'
import BasicModal from '../../../../shared/components/BasicModal'
import type {AnomalyWithReasonInterface} from '../../../../shared/interfaces/anomaly'
import AnomalyChat from './AnomalyChat'
import AnomalyFeedbackForm from './AnomalyFeedbackForm'
// import {useUpdateAnomalyLimitedFieldsMutation} from '../../../../apis/anomalies'
import AnomalyFeedbackchatModalTitle from './AnomalyFeedbackChatModalTitle'
import {ICON_BUTTON_SIZES} from '../../../../shared/constants/IconButtonSizes.ts'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation.ts'
import AnomalyDiscussionDetails from './AnomalyDiscussionDetails.tsx'
import {useResizeObserver} from '../../../../shared/hooks/useResizeObserver.ts'
import {useAnomalyStatusColor} from '../../../../shared/hooks/GetAnomalyStatusHook.tsx'
import {AnomalyStatus} from '../../../../shared/constants/anomalyStatusEnum.ts'

type Props = {
    row: AnomalyWithReasonInterface,
	page?: string,
	triggerFunction?: () => void,
	isFetching?: boolean,
}

const AnomalyFeedbackChatModal: FC<Props> = ({
	row, page = 'main-anomalies', triggerFunction, isFetching,
}) => {
	// States
	const [open, setOpen] = useState(false)
	const [status, setStatus] = useState<string>(row.status)
	const [anomalyDetailsHeight, setAnomalyDetailsHeight] = useState<number>(300)
	const {getAnomalyStatusColor} = useAnomalyStatusColor()
	// Hooks
	const [t] = useCustomTranslation('anomalies')

	// RTK Queries
	// const [updateAnomalyLimitedFields] = useUpdateAnomalyLimitedFieldsMutation()

	// Constants
	const MAIN_HEIGHT = '70vh'

	// useEffect(() => {
	// 	if (open && row.status === AnomalyStatus.DETECTED) {
	// 		updateAnomalyLimitedFields({
	// 			id: row.id,
	// 			status: AnomalyStatus.UNDER_REVIEW,
	// 			is_read: true,
	// 		})
	// 		setStatus(AnomalyStatus.UNDER_REVIEW)
	// 	}
	// }, [open, row.status, row.id, updateAnomalyLimitedFields])

	const handleClose = (): void => {
		setOpen(false)
	}

	const handleOpen = (event: BaseSyntheticEvent, value: boolean): void => {
		event.stopPropagation()
		setOpen(value)
	}

	const handleResize = useCallback((entry: ResizeObserverEntry): void => {
		const {target} = entry

		// Get the computed style of the target element
		const computedStyle = getComputedStyle(target)

		// Extract the padding values
		const paddingTop = parseFloat(computedStyle.paddingTop)
		const paddingBottom = parseFloat(computedStyle.paddingBottom)
		const borderTop = parseFloat(computedStyle.borderTopWidth)

		// Update dimensions
		setAnomalyDetailsHeight(entry.contentRect.height + paddingTop + paddingBottom + borderTop)
	}, [])

	const anomalyFeedbackFormDetailsRef = useResizeObserver(handleResize)

	const getAnomalyByStatusAndValidAnomaly = (status: string, isValidAnomaly: boolean | undefined| null): string => {
		if (status === AnomalyStatus.DETECTED) {
			return getAnomalyStatusColor('orange')
		}
		if (status === AnomalyStatus.CLASSIFIED) {
			return isValidAnomaly ? getAnomalyStatusColor('red') : getAnomalyStatusColor('green')
		}
		return ''
	}

	const onCreationOFAnomalyFeedbackSuccess = ():void => {
		setStatus(AnomalyStatus.CLASSIFIED)
		handleClose()
		if (triggerFunction) {
			triggerFunction()
		}
	}

	return (
		<>
			{isFetching ? (
				<CircularProgress
					style={{
						height: '16px',
						width: '16px',
					}}
				/>
			) : (
				<Tooltip
					title={t('anomaly_feedback_chat.open_anomaly_discussion')}
					placement={page !== 'main-anomalies' ? 'top-start' : 'left'}
				>
					<IconButton
						onClick={(event) => handleOpen(event, true)}
						data-testid="anomaly-feedback-chat-modal"
					>
						<ChatOutlinedIcon
							sx={{
								color: getAnomalyByStatusAndValidAnomaly(status, row.is_valid_anomaly),
								width: page !== 'main-anomalies' ? `${ICON_BUTTON_SIZES.medium}px` : undefined,
								height: page !== 'main-anomalies' ? `${ICON_BUTTON_SIZES.medium}px` : undefined,
							}}
						/>
					</IconButton>
				</Tooltip>
			)}
			{open ? (
				<Box onClick={(event) => event.stopPropagation()}>
					<BasicModal
						onClose={handleClose}
						title={(
							<AnomalyFeedbackchatModalTitle
								status={status}
							/>
						)}
						open={open}
						maxWidth="1200px"
						wrappers={false}
					>
						<Grid
							container
							spacing={0}
							sx={{
								backgroundColor: 'background.default',
								height: MAIN_HEIGHT,
							}}
						>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<Box
									sx={{
										borderRight: 1,
										borderColor: 'divider',
									}}
								>
									<Box
										sx={{
											py: 1.5,
											px: 2.5,
											borderBottom: 1,
											borderColor: 'divider',
										}}
										ref={anomalyFeedbackFormDetailsRef}
									>
										<Typography
											variant="h4"
											component="h3"
											sx={{mb: 0.5}}
										>
											{t('anomaly_feedback_chat.details')}
										</Typography>
										<Box>
											<AnomalyDiscussionDetails
												row={row}
											/>
										</Box>
									</Box>
									<AnomalyFeedbackForm
										anomalyId={row.id}
										mainHeight={`calc(${MAIN_HEIGHT} - ${anomalyDetailsHeight}px)`}
										onSuccess={onCreationOFAnomalyFeedbackSuccess}
									/>
								</Box>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<Box
									sx={{
										py: 1.5,
										px: 2.5,
										borderBottom: 1,
										borderColor: 'divider',
									}}
								>
									<Typography
										variant="h4"
										component="h3"
									>
										{t('anomaly_feedback_chat.discussion')}
									</Typography>
								</Box>
								<AnomalyChat
									anomalyId={row.id}
									mainHeight={MAIN_HEIGHT}
								/>
							</Grid>
						</Grid>
					</BasicModal>
				</Box>
			) : null}
		</>
	)
}

export default AnomalyFeedbackChatModal
