import type {FC} from 'react'
import {Typography} from '@mui/material'
import {useCustomTranslation} from '../hooks/useCustomTranslation'

type Props = {
    step: string | number,
    stepDescription?: string,
}

const StepDescription: FC<Props> = ({step, stepDescription}) => {
	const [t] = useCustomTranslation('anomalies')

	return (
		!step || step === 'None' ? null : (
			<Typography
				data-testid="step-description"
				variant="body2"
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: '100%',

				}}
			>
				{ `${t('step')} ${step}${stepDescription ? ` (${stepDescription})` : ''}`}
			</Typography>
		)
	)
}

export default StepDescription
