import type {FC} from 'react'
import {useEffect, useRef} from 'react'
import type {ScaleLinear} from 'd3'
import {select, axisLeft} from 'd3'

interface AxisProps {
  yScale: ScaleLinear<number, number> | null,
  width: number,
  tickValues?: number[],
  tickFormat?: boolean,
}

const LinearYAxis: FC<AxisProps> = ({
	yScale, width, tickValues, tickFormat = true,
}) => {
	const lineYAxisRef = useRef<SVGGElement | null>(null)

	useEffect(() => {
		const {current} = lineYAxisRef
		if (!current) return
		const yAxisSelection = select(current as SVGGElement)
		const yAxis = axisLeft(yScale as ScaleLinear<number, number>)
			.ticks(tickValues ? tickValues.length : 5)
			.tickPadding(10)
			.tickSizeOuter(5)
			.tickSizeInner(-width)

		if (tickValues) {
			yAxis.tickValues(tickValues)
		}

		if (tickFormat) {
			yAxis.tickFormat((value) => `${value}`) // Use 'as any' to satisfy TypeScript
		}

		yAxisSelection.call(yAxis)

		// Apply styles to ticks
		yAxisSelection.selectAll('.tick line')
			.attr('stroke', 'currentColor')
			.attr('stroke-width', 0.5)
			.attr('stroke-opacity', 0.2)
	}, [yScale, width, tickFormat, tickValues])

	return (
		<g
			data-testid="mbai-main-d3-graph-linear-y-axis"
			ref={lineYAxisRef}
		/>
	)
}

export default LinearYAxis
