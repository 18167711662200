import type {FC} from 'react'
import FilterSelect from '../../../../helpers/FilterSelect'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'

const AnomalyFilterByDetectionType: FC = () => {
	const [t] = useCustomTranslation('anomalies')
	const options = [
		{
			key: 'All', value: 'All', label: t('anomaly_feedback_chat.all'),
		},
		{
			key: 'step_signal', value: 'step_signal', label: t('step_signal'),
		},
		{
			key: 'value_based', value: 'value_based', label: t('value_based'),
		},
	]

	return (
		<FilterSelect
			options={options}
			defaultValue="All"
			paramTitle="detection_type"
			label="Detection Type"
		/>
	)
}

export default AnomalyFilterByDetectionType
