import type {FC} from 'react'
import {useEffect, useState} from 'react'
import {
	Box, Typography,
	useTheme,
} from '@mui/material'
import type {
	GridColDef,
	GridRenderCellParams,
} from '@mui/x-data-grid'
import {DataGrid} from '@mui/x-data-grid'
import {useSearchParams} from 'react-router-dom'
import {useLazyGetAnomaliesQuery} from '../../../../apis/anomalies'
import {tablesFullPage} from '../../../../shared/constants/lineManagement'
import {useGetProgramsQuery} from '../../../../apis/lineManagement'
import {useGetEquipmentsQuery, useGetLinesQuery} from '../../../../apis/base'
import SelectComponent from '../../BatchTraceability/components/SelectComponent'
import {useCustomTranslation} from '../../../../shared/hooks/useCustomTranslation'
import {getFormattedDate} from '../../../../util-functions'
import type {UserContextProps} from '../../../App/UserProvider'
import {useUserContext} from '../../../App/UserProvider'
import GoToAnomaly from './GoToAnomalyButton'
import BasicCard from '../../../../shared/components/BasicCard'
import AnomalyChatModal from '../AnomalyChatFeedback/AnomalyFeedbackChatModal'
import AnomalyFilterByStatus from './AnomalyFilterByStatus'
import {DEFAULT_OFFSET, DEFAULT_PAGE_SIZE} from '../../../../shared/constants/preferences/pagination'
import TrueFalseIconBasicComponent from '../../../../shared/components/TrueFalseIconBasicComponent'
import StepDescription from '../../../../shared/components/StepDescription'
import AnomalyFilterByDetectionType from './AnomalyFilterByDetectionType'

const AnomalyTable: FC = () => {
	const theme = useTheme()

	// States
	const [paginationModel, setPaginationModel] = useState({pageSize: DEFAULT_PAGE_SIZE, page: DEFAULT_OFFSET})
	const {currentUser} = useUserContext() as UserContextProps
	const [searchParams, setSearchParams] = useSearchParams()

	// Basic Variables
	const currentProgramId = searchParams.get('program') || ''
	const currentEquipmentId = searchParams.get('equipment') || ''
	const currentLineId = searchParams.get('line') || ''

	// Hooks
	const [t2] = useCustomTranslation('batch_traceability')
	const [t] = useCustomTranslation('anomalies')

	// RTK Queries
	const {data: lines, isFetching: linesQueryIsFetching} = useGetLinesQuery()
	const {data: equipments, isFetching: equipmentsIsFetching} = useGetEquipmentsQuery({lineId: currentLineId}, {skip: !currentLineId})
	const {data: programs, isFetching: programsIsFetching} = useGetProgramsQuery({
		lineId: currentLineId,
		equipmentId: currentEquipmentId,
	}, {skip: !currentLineId || !currentEquipmentId})

	const [trigger, {data: anomalies, isFetching: anomaliesIsFetching}] = useLazyGetAnomaliesQuery()

	const triggerFunction = ():void => {
		const status = searchParams.get('status')
		const detection_type = searchParams.get('detection_type')
		trigger({
			programId: currentProgramId === 'null' ? undefined : currentProgramId,
			machineId: currentLineId === 'null' ? undefined : currentLineId,
			machineSubSystemId: currentEquipmentId === 'null' ? undefined : currentEquipmentId,
			offset: paginationModel.page * paginationModel.pageSize,
			limit: paginationModel.pageSize,
			status: !status || status === 'All' ? '' : status,
			anomaly_detection_type: !detection_type || detection_type === 'All' ? '' : detection_type,
		})
	}

	useEffect(() => {
		triggerFunction()
	}, [currentProgramId, currentEquipmentId, currentLineId, paginationModel, searchParams])

	const handleLineChange = (value: string | string[]): void => {
		searchParams.set('line', value as string)
		searchParams.delete('equipment')
		searchParams.delete('program')
		setSearchParams(searchParams, {replace: true})
	}

	const handleEquipmentChange = (value: string | string[]): void => {
		searchParams.set('equipment', value as string)
		searchParams.delete('program')
		setSearchParams(searchParams, {replace: true})
	}

	const handleProgramChange = (value: string | string[]): void => {
		searchParams.set('program', value as string)
		setSearchParams(searchParams, {replace: true})
	}

	const columns: GridColDef[] = [
		{
			field: 'created_at',
			headerName: t('created_on'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row ? getFormattedDate(row.created_at, currentUser, {
				showDate: true, showYear: true, showTime: true,
			}) : ''),
		},
		{
			field: 'machine',
			headerName: t('line'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row ? row.machine.name : ''),
		},
		{
			field: 'machine_sub_system',
			headerName: t('equipment'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row ? row.machine_sub_system.name : ''),
		},
		{
			field: 'program',
			headerName: t('program'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row ? row.program.name : ''),
		},
		{
			field: 'batch',
			headerName: t('batch'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row ? row.batch.name : ''),
		},
		{
			field: 'signal',
			headerName: t('signal'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row ? row.signal.name : ''),
		},
		{
			field: 'start',
			headerName: t('start'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row && row.start ? getFormattedDate(row.start, currentUser, {
				showDate: true, showYear: true, showTime: true,
			}) : ''),
		},
		{
			field: 'end',
			headerName: t('end'),
			editable: false,
			flex: 1,
			valueGetter: (_, row) => (row && row.end ? getFormattedDate(row.end, currentUser, {
				showDate: true, showYear: true, showTime: true,
			}) : ''),
		},
		{
			field: 'step',
			headerName: t('step'),
			editable: false,
			flex: 1,
			renderCell: (params: GridRenderCellParams) => (
				<StepDescription
					step={params.row.step}
					stepDescription={params.row.step_description?.description}
				/>
			),
		},
		{
			field: 'anomaly_detection_type',
			headerName: t('detection_type'),
			editable: false,
			valueGetter: (_, row) => t(row.anomaly_detection_type) || '',
		},
		{
			field: 'score', headerName: t('score'), editable: false, flex: 1,
		},
		{
			field: 'reason', headerName: t('reason'), flex: 2,
		},
		{
			field: 'is_valid_anomaly',
			headerName: t('is_valid_anomaly'),
			flex: 0.5,
			renderCell: (params: GridRenderCellParams) => (
				<TrueFalseIconBasicComponent value={params.row.is_valid_anomaly} />
			),
		},
		{
			field: 'chat',
			headerName: '',
			width: 60,
			renderCell: (params: GridRenderCellParams) => (
				<AnomalyChatModal
					row={params.row}
					triggerFunction={triggerFunction}
					isFetching={anomaliesIsFetching}
				/>
			),
			disableColumnMenu: true,
			sortable: false,
		},
		{
			field: 'go_to_anomaly',
			headerName: '',
			width: 60,
			renderCell: (params: GridRenderCellParams) => <GoToAnomaly row={params.row} />,
			disableColumnMenu: true,
			sortable: false,
		},
	]

	return (
		<>
			<Box
				sx={
					{
						display: 'flex',
						flexDirection: 'row',
						mb: 2,
						justifyContent: 'space-between',
						alignItems: 'center',
					}
				}
				data-testid="anomaly-table-box"
			>
				<Box
					sx={{
						display: 'flex', flexDirection: 'row', gap: 2,
					}}
				>
					<Typography
						variant="h2"
						component="h3"
						sx={{whiteSpace: 'nowrap', alignSelf: 'center'}}
					>
						{`${t('anomalies')} (Beta)`}
					</Typography>
					<AnomalyFilterByStatus />
					<AnomalyFilterByDetectionType />
				</Box>
				<Box
					sx={
						{
							display: 'flex', flexDirection: 'row', width: '50%', gap: 2,
						}
					}
				>
					<SelectComponent
						label={t('select_line')}
						isLoading={linesQueryIsFetching}
						data={lines || []}
						value={currentLineId || undefined}
						onChange={handleLineChange}
						inputProps={
							{id: 'machine-select'}
						}
						noDataMessage={t2('no_lines_found')}
						displayLabel={false}
					/>
					<SelectComponent
						label={t('select_equipment')}
						isLoading={equipmentsIsFetching}
						data={equipments || []}
						value={currentEquipmentId || undefined}
						onChange={handleEquipmentChange}
						inputProps={
							{id: 'machine-select'}
						}
						noDataMessage={t2('no_lines_found')}
						disabled={!currentLineId || equipments?.length === 0}
						displayLabel={false}
					/>
					<SelectComponent
						label={t('select_program')}
						isLoading={programsIsFetching}
						data={programs || []}
						value={currentProgramId || undefined}
						onChange={handleProgramChange}
						inputProps={
							{id: 'machine-select'}
						}
						noDataMessage={t2('no_lines_found')}
						disabled={!programs || programs.length === 0}
						displayLabel={false}
					/>
				</Box>
			</Box>
			<BasicCard
				id="signals-box"
				data-testid="mbai-main-line-management-signals-table"
				className="selectorConfigureLinePageSignals"
				data-cy="mbai-main-line-management-signals-table"
			>
				<DataGrid
					rows={anomalies?.results || []}
					rowCount={anomalies?.count ?? 0}
					columns={columns}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					paginationMode="server"
					rowHeight={tablesFullPage.rowHeight}
					disableRowSelectionOnClick
					pageSizeOptions={[10, 50, 100]}
					loading={anomaliesIsFetching || linesQueryIsFetching || equipmentsIsFetching || programsIsFetching}
					sx={
						{
							height: 'calc(100vh - 165px)',
							border: 'none',
							'--DataGrid-containerBackground': theme.palette.background.paper,
						}
					}
				/>
			</BasicCard>
		</>
	)
}

export default AnomalyTable
